html {
    scroll-behavior: smooth;
}

input {
    font-family: Helvetica, sans-serif;
}

#playerView {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    min-height: fit-content;
    width: 100vw;
    min-width: fit-content;
    text-align: center;
    background: linear-gradient(to bottom, #f0ebd8, #b3d9e0);
    color: #4b2e1f;
    font-family: Helvetica, sans-serif;
}

#playerView #playerInputRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    max-width: 50em;
}

#playerView #playerInput {
    font-size: 1.2rem;
    width: 7em;
    max-width: 15em;
    padding: 10px;
    margin: 10px 0;
    border: 2px solid #ccc;
    border-radius: 8px;
    text-align: center;
}

#playerView a {
    display: inline-block;
    text-decoration: none;
    background-color: #a5c3ca;
    color: #2d3e40;
    padding: 10px;
    border-radius: 8px;
    font-size: 1.2rem;
    text-align: center;
    transition: background 0.3s;
    opacity: 0.9;
    width: 7em;
}

#playerView a:hover {
    background-color: #0056b3;
}

#playerView #playerIdRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
}

#playerView #playerIdRow .playerName {
    margin-left: 5px;
}

/* Style the tournament details */
#playerView .roundInfo {
    font-size: 1rem;
    line-height: 1.6;
    margin: 0 auto;
}

#playerView .courtName {
    display: none;
}

#playerView .courtInfo {
    text-align: center;
    font-size: 2rem;
    float: left;
    width: 100%;
}

#playerView .court {
    background-size: auto;
}

#playerView h2 {
    margin-bottom: 0;
}
#playerView #allRounds {
    margin-top: 0;
    text-align: left;
    list-style: none;
}

#playerView #allRounds li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
}

#playerView #error {
    padding: 10vh 10vw;
    font-size: 1.5rem;
}

.court:has(.t2) {
    margin-bottom: 85px;
}

#playerView .circle {
    display: flex;
    flex: 0 0 50px;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: bold;
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    margin-right: 10px;
}

#playerView .circle.neutral {
    background: linear-gradient(to bottom right, #e0e0e0, #c0c0c0); /* Soft gray gradient */
    color: #6b6b6b; /* Darker gray text */
}
#playerView .circle.win {
    background: linear-gradient(to bottom right, #4caf50, #81c784); /* Green gradient */
}

#playerView .circle.lose {
    background: linear-gradient(to bottom right, #f44336, #e57373); /* Red gradient */
}

#playerView .circle:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

#playerView #explanation {
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
}

#playerView #explanation .circle {
    display: inline-flex;
    width: 1.2em;
    height: 1.2em;
    border-radius: 50%;
    font-weight: bold;
    cursor: default;
    margin-right: 0;
}