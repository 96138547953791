h1 {
    text-align: center;
    font-size: 3em;
    font-weight: bold;
}

#leaderboard {
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    background: url('img/erika/bg1-new-high.jpg') no-repeat center;
    background-size: cover;
    justify-content: center;
    align-items: center;
}

.leaderboard-tables {
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: flex-start;
}

table {
    font-size: 1.5rem;
    border-collapse: collapse;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    border-radius: 10px;
    overflow: hidden;
    min-width: 300px;
}

th, td {
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

th {
    text-align: left;
}

th.numeric, td.numeric {
    text-align: center;
}

@media (max-width: 1024px) {
    .leaderboard-tables {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    table {
        width: 90%;
    }
}