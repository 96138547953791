.court {
	position: relative;
	background-size: contain;
	background-repeat: no-repeat;
}

.player {
	border-radius: 50%;
	background-color: #22aa22;
	opacity: 0.6;
	text-align: center;
	font-weight: bold;
}

.player.color0 {
	background-color: #ffb700;
	color: black;
}

.player.color1 {
	background-color: #ffee00;
	color: black;
}

.player.color2 {
	background-color: #28ff02;
	color: black;
}

.player.color3 {
	background-color: #04ffe2;
	color: black;
}

.player.color4 {
	background-color: #3f4040;
	color: white;
}

.player.color5 {
	background-color: #7b00ff;
	color: white;
}

.player.color6 {
	background-color: #e600ff;
	color: white;
}

.player.color7 {
	background-color: #ff0220;
	color: white;
}

.player.color8 {
	background-color: #ff3f04;
	color: black;
}

.player.color9 {
	background-color: #dedcdb;
	color: black;
}

.court .courtName {
	position: absolute;
	width: 100%;
	text-align: center;
	margin: 0;
}

.court .team {
	position: absolute;
	left: 10%;
	right: 10%;
	display: flex;
	justify-content: space-evenly;
}

.t0.row0 {
	top: 2%;
}

.t0.row0.oneRow {
	top: 10%;
}

.t0.row1 {
	top: 25%;
}

.t1.row1 {
	bottom: 25%;
}

.t1.row0 {
	bottom: 2%;
}

.t1.row0.oneRow {
	bottom: 10%;
}

.t2.row1 {
	bottom: -32%;
}

.t2.row0 {
	bottom: -17%;
}

.t2.row0.oneRow {
	bottom: -24%;
}

.player {
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 1.5em;
}

.courtSize0 {
	background-image: url('img/erika/court-50.png');
	width: 50px;
	height: 82px;
}

.courtSize0 .player.fit2 {
	width: 18px;
	height: 18px;
	line-height: 18px;
	font-size: 0.7em;
}

.courtSize0 .player.fit2.digits3 {
	font-size: 0.6em;
}

.courtSize0 .player.fit3 {
	width: 15px;
	height: 15px;
	line-height: 15px;
	font-size: 0.5em;
}

.courtSize0 .player.fit3.digits3 {
	font-size: 0.15em;
}

.courtSize0 .courtName {
	font-size: 3.5em;
	top: -1em;
}

.courtSize1 {
	background-image: url('img/erika/court-100.png');
	width: 100px;
	height: 166px;
}

.courtSize1 .player.fit2 {
	width: 38px;
	height: 38px;
	line-height: 38px;
	font-size: 1.6em;
}

.courtSize1 .player.fit2.digits3 {
	font-size: 1.2em;
}

.courtSize1 .player.fit3 {
	width: 25px;
	height: 25px;
	line-height: 25px;
	font-size: 0.9em;
}

.courtSize1 .player.fit3.digits3 {
	font-size: 0.7em;
}

.courtSize1 .courtName {
	font-size: 4em;
	top: -1em;
}

.courtSize2 {
	background-image: url('img/erika/court-150.png');
	width: 150px;
	height: 248px;
}

.courtSize2 .player.fit2 {
	width: 55px;
	height: 55px;
	line-height: 55px;
	font-size: 2.2em;
}

.courtSize2 .player.fit2.digits3 {
	font-size: 1.8em;
}

.courtSize2 .player.fit3 {
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 1.6em;
}

.courtSize2 .player.fit3.digits3 {
	font-size: 1.2em;
}

.courtSize2 .courtName {
	font-size: 4em;
	top: -1em;
}

.courtSize3 {
	background-image: url('img/erika/court-200.png');
	width: 200px;
	height: 332px;
}

.courtSize3 .player.fit2 {
	width: 75px;
	height: 75px;
	line-height: 75px;
	font-size: 2.9em;
}

.courtSize3 .player.fit2.digits3 {
	font-size: 2.4em;
}

.courtSize3 .player.fit3 {
	width: 50px;
	height: 50px;
	line-height: 50px;
	font-size: 2.0em;
}

.courtSize3 .player.fit3.digits3 {
	font-size: 1.5em;
}

.courtSize3 .courtName {
	font-size: 4em;
	top: -1em;
}

.courtSize4 {
	background-image: url('img/erika/court-220.png');
	width: 220px;
	height: 365px;
}

.courtSize4 .player.fit2 {
	width: 80px;
	height: 80px;
	line-height: 80px;
	font-size: 3.0em;
}

.courtSize4 .player.fit2.digits3 {
	font-size: 2.5em;
}

.courtSize4 .player.fit3 {
	width: 56px;
	height: 56px;
	line-height: 56px;
	font-size: 2.2em;
}

.courtSize4 .player.fit3.digits3 {
	font-size: 1.6em;
}

.courtSize4 .courtName {
	font-size: 5em;
	top: -1em;
}

.courtSize5 {
	background-image: url('img/erika/court-300.png');
	width: 300px;
	height: 498px;
}

.courtSize5 .player.fit2 {
	width: 110px;
	height: 110px;
	line-height: 110px;
	font-size: 4.9em;
}

.courtSize5 .player.fit2.digits3 {
	font-size: 3.8em;
}

.courtSize5 .player.fit3 {
	width: 76px;
	height: 76px;
	line-height: 76px;
	font-size: 3.1em;
}

.courtSize5 .player.fit3.digits3 {
	font-size: 2.3em;
}

.courtSize5 .courtName {
	font-size: 6em;
	top: -1em;
}

.courtSize6 {
	background-image: url('img/erika/court-400.png');
	width: 400px;
	height: 663px;
}

.courtSize6 .player.fit2 {
	width: 140px;
	height: 140px;
	line-height: 140px;
	font-size: 6.0em;
}

.courtSize6 .player.fit2.digits3 {
	font-size: 4.8em;
}

.courtSize6 .player.fit3 {
	width: 100px;
	height: 100px;
	line-height: 100px;
	font-size: 4.7em;
}

.courtSize6 .player.fit3.digits3 {
	font-size: 3.2em;
}

.courtSize6 .courtName {
	font-size: 6em;
	top: -1em;
}

.courtSize7 {
	background-image: url('img/erika/court-500.png');
	width: 500px;
	height: 830px;
}

.courtSize7 .player.fit2 {
	width: 180px;
	height: 180px;
	line-height: 180px;
	font-size: 8em;
}

.courtSize7 .player.fit2.digits3 {
	font-size: 6.2em;
}

.courtSize7 .player.fit3 {
	width: 125px;
	height: 125px;
	line-height: 125px;
	font-size: 5.8em;
}

.courtSize7 .player.fit3.digits3 {
	font-size: 4.2em;
}

.courtSize7 .courtName {
	font-size: 6em;
	top: -1em;
}

.W {
	border: 4px green solid;
}

.L {
	border: 4px red solid;
}

.clickable {
	cursor: pointer;
}

.clickable:hover {
	transform: scale(1.1);
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}